import React, { Component } from 'react';
/** Components */
import { LogoPeque } from '../logo';
import ProfileTabs from '../components/ProfileTabs';
import InfoPerfil from '../components/infoPerfil.js';
/** Services / Utils */
import { showErrorAlert } from '../utils/alerts';
import { getCurrentUser } from '../utils/services/authService';
import { getUserInfo } from '../utils/services/userService';
/** CSS */
import defaultUser from '../assets/img/default-user.svg';
import '../components/form.css';
import '../assets/css/profile.css';

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      load: false,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  async getData() {
    try {
      const token = localStorage.getItem('authToken');
      const result = await getCurrentUser(token);
      const user = getUserInfo(result.data);

      this.setState({
        user: user.name,
        building: user && user.building ? user.building.name : '',
        tower: user && user.building ? user.building.address1 : '',
        phone: user.phone,
        apartmentDescription: user && user.apartment ? user.apartment.name : '',
        load: true,
        isResidentLeader: user.role && user.role.name === 'RESIDENTE_LIDER',
      });
    } catch (error) {
      showErrorAlert('ups', `Ha ocurrido un error ${error.message || JSON.stringify(error)}`);
    }
  }

  render() {
    const { load, isResidentLeader } = this.state;
    if (load) {
      return (
        <div className="App bg-blue">
          <div className="container" style={{ paddingBottom: 0 }}>
            <a onClick={this.goBack} className={'btn-back'} />
            <LogoPeque />
            <InfoPerfil
              username={this.state.user}
              usercity={this.state.building}
              userinterior={this.state.tower}
              userapartamento={this.state.apartmentDescription}
              userphone={this.state.phone}
              userimage={defaultUser}
            />
            {isResidentLeader ? <ProfileTabs /> : <div />}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  goBack() {
    window.history.back();
  }
}

export default Profile;
