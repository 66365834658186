import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/votaciones.css';

export class AccessRequestRow extends Component {
  render() {
    return (
      <li>
        <p className="notTitulo">{this.props.title}</p>
        <p className="notcontenido">{this.props.subtitle}</p>
        <p className="countdown col-6 float-left">{this.props.description}</p>
        <div className="votaBotones col-6">
          <a className="votaSi" onClick={this.props.approvedAction.bind(this, this.props.id)}></a>
          <a className="votaNo" onClick={this.props.notApprovedAction.bind(this, this.props.id)}></a>
        </div>
      </li>
    );
  }
}

AccessRequestRow.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  approvedAction: PropTypes.func,
  notApprovedAction: PropTypes.func,
};

export default AccessRequestRow;
