import { url_base } from '../../config/url';
import axios from 'axios';

export async function getCardsByDocument(document) {
  const result = await axios({
    method: 'get',
    url: `${url_base}/api/bluebuilding/cardsv2?document=${document}`,
    headers: {
      Authorization: `JWT ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
  });

  return result?.data;
}

export function createCard(data) {
  return axios.post(`${url_base}/api/bluebuilding/cardsv2`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
  });
}

export async function updateCardByCardId(cardId, data) {
  const result = await axios({
    method: 'put',
    url: `${url_base}/api/bluebuilding/cardsv2/${cardId}/`,

    headers: {
      Authorization: `JWT ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
    data,
  });

  return result?.data;
}

export async function deleteCardByCardId(cardId) {
  const result = await axios({
    method: 'delete',
    url: `${url_base}/api/bluebuilding/cardsv2/${cardId}/`,

    headers: {
      Authorization: `JWT ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
  });

  return result?.data;
}
