import React, { useState, useEffect } from 'react';
import UsersList from '../../../../components/UsersList';
import { getUsers } from '../../../../utils/services/userService';
import { showErrorAlert } from '../../../../utils/alerts';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchUser, setSearchUser] = useState('');

  const filteredUsers = users.filter((item) => item.user.cedula.toString().includes(searchUser));

  const getResidents = async () => {
    try {
      const users = await getUsers({ userType: 'USER' });

      setUsers(users);
    } catch (error) {
      console.error('Error', error);
      showErrorAlert('Error', error.message);
    }
  };

  useEffect(() => {
    getResidents();
  }, []);

  return (
    <div>
      <div className="mb-3 row w-100 text-form">
        <h2>Administrar usuarios</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <input
          type="number"
          placeholder="Buscar por documento"
          value={searchUser}
          onChange={(e) => {
            const value = e.target.value;
            setSearchUser(value);
          }}
          className="w-50 p-3 border rounded"
        />
      </div>
      <UsersList users={filteredUsers} onUserSave={getResidents} />
    </div>
  );
};

export default UserManagement;
