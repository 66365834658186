import { io } from 'socket.io-client';
import { urlSocket } from '../config/url';

const socket = io(urlSocket, {
  transports: ['websocket'],
});

socket.on('connect', () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    socket.emit('init', { token });
  }
});

socket.on('disconnect', (reason) => {
  if (reason === 'io server disconnect') {
    // the disconnection was initiated by the server by socket.disconnect(), so
    // we need to terminate the user session
    localStorage.clear();
    window.location.href = '/';
  }
});

export default socket;
