import { getCurrentUser } from '../utils/services/authService';
import { requestFirebaseNotificationPermission } from '../config/firebase';
import { saveNotificationsToken } from './services/userService';

const checkNotificationsPermission = async (user) => {
  try {
    const registrationToken = await requestFirebaseNotificationPermission();
    if (registrationToken && (!user.tokenFirebase || registrationToken !== user.tokenFirebase)) {
      const data = {
        registrationToken,
      };
      await saveNotificationsToken(data);
      console.log('Token guardado exitosamente');
    }
  } catch (error) {
    console.error({ error });
  }
};

export const checkCurrentUser = async function checkCurrentUser() {
  const token = localStorage.getItem('authToken');

  if (token) {
    try {
      const {
        data: { user, spaces },
      } = await getCurrentUser(token);
      checkNotificationsPermission(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userId', user.id);
      localStorage.setItem('spaces', JSON.stringify(spaces));
    } catch (error) {
      const { response } = error;
      if (response.status === 401) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('spaces');
      }
    }
  }
};
