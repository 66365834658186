import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/camaras.css';

export class MapaHeader extends Component {
  handleFloorSelected(event) {
    const floorId = +event.target.value;
    this.props.onFloorChanged(floorId);
  }

  render() {
    const { selection, label, floors } = this.props;
    return (
      <div className="mapaHeader">
        <div className="row p-3">
          <div className="col-8 text-left">
            <h2>{(selection && selection.tower && selection.tower.name) || ''}</h2>
            <p>{label}</p>
          </div>
          <div className="col-4 text-right pl-0">
            <select
              onChange={this.handleFloorSelected.bind(this)}
              className="select w-100"
              name="floorId"
              id="select-floor"
              value={selection && selection.floor && selection.floor.id}
              required
            >
              <option defaultValue="">Selecciona el piso</option>
              {floors &&
                floors.length > 0 &&
                floors
                  .sort((floorA, floorB) => floorA.name.localeCompare(floorB.name))
                  .map((floor) => (
                    <option key={floor.id} value={floor.id}>
                      {floor.name}
                    </option>
                  ))}{' '}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

MapaHeader.propTypes = {
  selection: PropTypes.object,
  label: PropTypes.string,
  floors: PropTypes.array,
  onFloorChanged: PropTypes.func,
};

export default MapaHeader;
