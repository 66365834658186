import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import socket from './../utils/socket';
/** Components */
import { LogoPeque } from '../logo';
/** Enums */
import { EVENT_TYPE } from '../utils/enums';
/** Services */
import { getCurrentUser } from '../utils/services/authService';
import { showErrorAlert } from '../utils/alerts';
/** CSS */
import '../assets/css/modal.css';

class SuspectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
    this.errorCleaner = null;
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken');
    const {
      data: { spaces },
    } = await getCurrentUser(token);
    const [{ building, role }] = spaces;
    this.setState({
      building,
      role,
    });

    socket.on('user_event:error', ({ error }) => {
      const message = error.message || JSON.stringify(error);
      console.error(message);
      this.setState({ error: message });
      /** Reset error */
      this.errorCleaner = setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 5000);
    });
  }

  async handleTriggerEvent(event) {
    try {
      const { building } = this.state;
      const data = {
        event,
        buildingId: building ? building.id : null,
      };
      socket.emit('user_event', data);
    } catch (error) {
      showErrorAlert('¡Ups!', 'Ocurrió un error al realizar la notificación');
    }
  }

  render() {
    const { showModal, error, role } = this.state;
    return (
      <Modal
        open={showModal}
        onClose={this.props.onModalClosed.bind(this)}
        classNames={{ overlay: 'background-modal' }}
        center
      >
        <div className="alerta" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <LogoPeque />
              </h5>
              <button type="button" className="close" onClick={this.props.onModalClosed.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h2>Eventos</h2>
              <p>
                Usa esta opción si presencias alguna actividad sospechosa en alguna de las instalaciones o si tienes un
                caso de emergencia.
              </p>
            </div>
            <div className="modal-footer events">
              <button
                type="button"
                className="btn btn-event btn-primary btn-security"
                onClick={this.handleTriggerEvent.bind(this, EVENT_TYPE.INTRUSION)}
              >
                Alerta de Seguridad <br />
                (Intruso)
              </button>
              <button
                type="button"
                className="btn btn-event btn-primary"
                onClick={this.handleTriggerEvent.bind(this, EVENT_TYPE.EMERGENCY)}
              >
                Alerta de Emergencia <br />
                (terremotos, incendios...)
              </button>
              {role && ['RESIDENTE_LIDER', 'ADMINISTRADOR', 'PROPIETARIO'].indexOf(role.name) !== -1 ? (
                <button
                  type="button"
                  className="btn btn-event btn-success"
                  onClick={this.handleTriggerEvent.bind(this, EVENT_TYPE.MUTE)}
                >
                  Silenciar alarma <br />
                  (Sólo si ya revisaste que <br /> todo esté bien...)
                </button>
              ) : null}
            </div>
            {error && (
              <div className="alert alert-danger p-2" role="alert">
                {error}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

SuspectModal.propTypes = {
  onModalClosed: PropTypes.func,
};

export default SuspectModal;
