import React, { useState } from 'react';
import { Logo } from '../logo';
// Components
import Caller from '../components/Call/Caller';
import IncomingCall from '../components/Call/IncomingCall';
import ApartmentsList from '../components/ApartmentsList/ApartmentsList';
/** Utils */
import { createCall } from '../utils/api';
/** CSS */
import '../components/form.css';
import '../assets/css/accesoVisitante.css';

function AccesoVisitante() {
  const [calls, setCalls] = useState(null);

  const initCall = async ({ users }) => {
    if (!users?.length) {
      alert('Apto no encontrado');
      return;
    }

    const callCreationPromises = users.map(async ({ id: userId, apartmentUserId }) => {
      const call = await createCall(apartmentUserId, 'VISITANTE');
      return {
        ...call,
        userId,
      };
    });

    const callsCreated = await Promise.all(callCreationPromises);

    setCalls(callsCreated);
  };

  return (
    <div className="App bg-blue">
      <div className="container">
        <Logo className="App-logo w-75" />
        <div className="row w-100 text-form">
          <div className="col-sm-12">
            <p>Digite el número del apartamento con el que desea comunicarse.</p>
          </div>
        </div>

        <ApartmentsList onCallApartment={initCall} />

        {calls?.length ? <Caller calls={calls} onCloseCaller={() => setCalls(null)} isFromLobby /> : null}

        <IncomingCall />
      </div>
    </div>
  );
}

export default AccesoVisitante;
