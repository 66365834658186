import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import '../assets/css/header.css';

export class Header extends Component {
  render() {
    return (
      <header className="header">
        <a onClick={this.handleGoBack.bind(this)} className={`btn-back ${this.props.display}`} />
        <link className="logoblue" />
        <a href="/profile" className="userimage">
          <img src={this.props.userimage} />
        </a>
      </header>
    );
  }

  handleGoBack() {
    this.props.history.goBack();
  }
}

Header.propTypes = {
  history: PropTypes.object,
  display: PropTypes.string,
  userimage: PropTypes.string,
};

export default withRouter(Header);
