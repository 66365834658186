import React, { useEffect, useState } from 'react';
import socket from '../../utils/socket';
import PropTypes from 'prop-types';

/** Components */
import Modal from 'react-responsive-modal';
import CallingModal from './CallingModal';
import { LogoPeque } from '../../logo';
import { showSuccessAlert } from '../../utils/alerts';

/** Services mmm*/
import { updateCall } from '../../utils/api';

import './Caller.css';

/** This is a wrapper that request the calls and handle the status */
function Caller({ calls, onCloseCaller, isFromLobby }) {
  const [callStatus, setCallStatus] = useState('');
  const [answeredCall, setAnsweredCall] = useState(null);
  const buildingName = localStorage.getItem('buildingName');
  const [messages, setMessages] = useState([]);

  let timer;
  let missedTimer;

  const unsubscribeToCallNotificationSteps = () => {
    if (!socket) return;
    socket.off('request:socket:success');
    socket.off('request:push_notification:success');
    socket.off('request:twilio:success');
    socket.off('request:sms:success');
  };

  useEffect(() => {
    if (!calls) return;
    initCalls();

    return () => {
      timer && clearTimeout(timer);
      socket.off('reject_call');
      socket.off('call_accepted');
      unsubscribeToCallNotificationSteps();
      missedTimer && clearTimeout(missedTimer);
      setMessages([]);
    };
  }, [calls]);

  /** Send the Call request to each device */
  const initCalls = async () => {
    calls.map((call) =>
      socket.emit('request', {
        to: call.userId,
        callId: call.id,
        isFromLobby,
        buildingName,
      }),
    );
    setCallStatus('calling');
    createTimer();
    listenCalls();
  };

  const cancelCalls = async () => {
    try {
      await Promise.all(
        calls.map(async (call) => {
          socket.emit('reject_call', { to: call.userId, callId: call.id });
          await updateCall(call.id, { status: 'TERMINADA' });
        }),
      );
      setCallStatus('');
      if (timer) clearTimeout(timer);
      onCloseCaller();
    } catch (e) {
      console.error(e);
    }
  };

  const createTimer = () => {
    timer = setTimeout(async () => {
      console.log('timeout waiting for accept call');
      try {
        await Promise.all(
          calls.map(async (call) => {
            socket.emit('reject_call', { to: call.userId, callId: call.id });
            await updateCall(call.id, { status: 'PERDIDA' });
          }),
        );
        setCallStatus('missed');
        missedTimer = setTimeout(() => {
          finishCall();
        }, 5000);
      } catch (e) {
        console.error(e);
      }
    }, 30000);
  };

  const finishCall = (rejectMessage) => {
    // if (answeredCall) {
    //   socket.emit("reject_call", { to: answeredCall.userId, callId: answeredCall.id });
    // }

    setAnsweredCall(null);
    setCallStatus('');

    showSuccessAlert(rejectMessage || 'El usuario ha rechazado tu llamada', 'Mensaje del residente');

    onCloseCaller();
  };

  const addMessage = (message) => {
    setMessages((prevMessages) => {
      return [...prevMessages, message];
    });
  };

  const listenCalls = () => {
    socket
      .on('request:socket:success', () => {
        addMessage('Estamos notificando al residente... ');
      })
      .on('request:push_notification:success', () => {
        addMessage('Ya hemos enviado una notificación al residente ');
      })
      .on('request:twilio:success', () => {
        addMessage('Ya hemos realizado una llamada automática al residente ');
      })
      .on('request:sms:success', () => {
        addMessage('Ya hemos enviado un SMS informando que estas en portería');
      })
      .on('call_accepted', async ({ callId }) => {
        if (timer) clearTimeout(timer);
        unsubscribeToCallNotificationSteps();

        const call = calls.find((c) => c.id === callId);
        if (call) setAnsweredCall(call);
        const callsNotAccepted = calls.filter((c) => c.id !== callId);

        /** Finish the not answered calls */
        if (callsNotAccepted?.length > 0) {
          await Promise.all(
            callsNotAccepted.map(async (call) => {
              socket.emit('reject_call', { to: call.userId, callId: call.id });
              await updateCall(call.id, { status: 'TERMINADA' });
            }),
          );
        }
      });

    socket.on('reject_call', (response) => {
      const rejectMessage = response?.rejectMessage;

      finishCall(rejectMessage);
    });
  };

  const missedCallModal = () => {
    return (
      <Modal open={callStatus === 'missed'} classNames={{ overlay: 'background-modal' }} onClose={onCloseCaller} center>
        <div className="modal-dialog modal-dialog-centered alerta" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                <LogoPeque />
              </h5>
              <button type="button" className="close" onClick={onCloseCaller}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {isFromLobby
                ? 'Ya hemos notificado al residente, en unos momentos te podrá llamar de vuelta, o intenta nuevamente en 1 minuto...'
                : 'El usuario no responde'}
            </div>
            <div className="modal-footer pa-button">
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={onCloseCaller}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const ringingView = () => {
    return (
      <div className="content-center">
        <button type="button" className="close ringing-close-button" onClick={() => cancelCalls()}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h1 className="title">Estamos contactando al Residente</h1>
        <div className="pulse">
          <span className="material-icons">call</span>
        </div>
        {messages?.length > 0 ? (
          <div className="messages">
            {messages.map((message, index) => (
              <p key={index} className="message">
                {message}
              </p>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  const renderCallStatus = () => {
    const callOptions = {
      calling: () => ringingView(),
      missed: () => missedCallModal(),
      default: () => ringingView(),
    };

    return (callOptions[callStatus] || callOptions.default)();
  };

  return answeredCall ? (
    <CallingModal call={answeredCall} onCloseCallingModal={() => finishCall()} isFromLobby={isFromLobby} />
  ) : (
    renderCallStatus()
  );
}

Caller.propTypes = {
  isFromLobby: PropTypes.bool,
  calls: PropTypes.array.isRequired,
  onCloseCaller: PropTypes.func.isRequired,
};

Caller.defaultProps = {
  isFromLobby: false,
};

export default Caller;
