import React from 'react';
import PropTypes from 'prop-types';
import NetworkStatus from './NetworkStatus';

const Layout = ({ children }) => {
  return (
    <div className="bg-blue">
      <main>{children}</main>
      <NetworkStatus />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
