import React, { Component } from 'react';
import Logo from '../logo';
import '../components/form.css';

export class Acceso extends Component {
  render() {
    return (
      <div className="App bg-blue">
        <div className="container">
          <Logo className="App-logo w-75" />
          <div className="row w-100 text-form">
            <div className="col-sm-12">
              <p>Bienvenido al Edificio, para iniciar seleccione su rol</p>
            </div>
          </div>
          <form className="row d-flex w-75 mx-auto">
            <a href="/acceso-visitante" className="btn w-100 mx-auto button-shape button">
              VISITANTE
            </a>

            {/* <a href="/acceso-domicilio" className="btn w-100 mx-auto button">DOMICILIO</a>

            <a href="/acceso-correspondencia" className="btn w-100 mx-auto button-shape-2 button">CORRESPONDENCIA</a> */}
          </form>
        </div>
      </div>
    );
  }
}

export default Acceso;
