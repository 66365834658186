import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { showErrorList } from '../../utils/alerts';
/** Components */
import Loader from '../../components/loader';
import { FormInput, FormButton } from '../../components/campo.js';
import { LogoPeque } from '../../logo';
/** Services */
import { getBuildings } from '../../utils/services/buildingService';
import { VisitorRegister } from '../../utils/services/visitorService';
import { validateMandatoryFields } from '../../utils/validator';
/** CSS */
import '../../index.css';
import { CONSTANS_DOCUMENT_TYPE } from '../../utils/enums';
import TerminosYCondiciones from '../../components/terminosYCondiciones';
import { getErrorsList } from '../../utils/errors';

export class FormApartmentVisitorRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitor: {
        name: '',
        cedula: '',
        documentType: 'CEDULA_CIUDADANIA',
        password: '',
        passwordConfirmation: '',
        phone: '',
        photo: null,
        email: '',
        buildingId: '',
        acceptTerms: false,
      },
      previewPhoto: null,
      formData: null,
      errors: {},
      error: null,
      loading: false,
      documentTypes: CONSTANS_DOCUMENT_TYPE,
      showTermsModal: false,
    };
  }

  async componentDidMount() {
    try {
      const buildings = await getBuildings();
      const buildingId = buildings && buildings.length === 1 ? buildings[0].id : '';
      const formData = new FormData();
      this.setState({
        formData,
        buildings,
        visitor: {
          ...this.state.visitor,
          buildingId,
        },
      });
    } catch (error) {
      console.log(`componentDidMount -> error`, error);
    }
  }

  handleCloseTermsModal() {
    this.setState({
      showTermsModal: false,
    });
  }

  render() {
    const { previewPhoto, errors, visitor, loading, buildings, showTermsModal } = this.state;
    return (
      <div className="App bg-blue">
        <div className="container">
          <a onClick={this.goBack} className={'btn-back'} />
          <LogoPeque />
          <form onSubmit={this.handleSubmit} className="row d-flex w-75 mx-auto justify-content-between">
            <div className="foto-user mx-auto">
              <div>
                {previewPhoto && (
                  <img
                    src={previewPhoto}
                    style={{ borderRadius: '50%' }}
                    alt="Imagen usuario"
                    width="110px"
                    height="110px"
                  />
                )}
              </div>
              <input type="file" name="photo" id="photo" accept="image/*" onChange={this.handleImage} />
            </div>
            {errors.previewPhoto && (
              <div className="error-form-image-container">
                <span className="error-image">* Debes agregar la foto</span>
              </div>
            )}
            <div
              style={{
                fontSize: '1.3em',
                margin: '1em',
                color: '#FFFFFF',
              }}
              className="w-100 mx-auto"
            >
              Registro Visitantes
            </div>
            <div className="skew-input input-shape w-100 mx-auto select">
              <select value={visitor.buildingId} name="buildingId" onChange={this.handleInputChange} id="buildingId">
                <option key="default_building" value="">
                  Selecciona un edificio
                </option>
                {buildings &&
                  buildings
                    .sort((buildingA, buildingB) => buildingA.name.localeCompare(buildingB.name))
                    .map((building) => (
                      <option key={building.id} value={building.id}>
                        {building.name}
                      </option>
                    ))}
              </select>
            </div>
            {errors.buildingId && (
              <div className="error-form-container">
                <span className="error-text">* Debes seleccionar un edificio</span>
              </div>
            )}
            <div className="skew-input input-shape-2 w-100 mx-auto select">
              <select
                value={visitor.documentType}
                name="documentType"
                onChange={this.handleInputChange}
                id="documentType"
                required
              >
                {Object.keys(this.state.documentTypes)
                  .sort((documentA, documentB) =>
                    this.state.documentTypes[documentA].localeCompare(this.state.documentTypes[documentB]),
                  )
                  .map((documentTypeKey) => (
                    <option key={documentTypeKey} value={documentTypeKey}>
                      {this.state.documentTypes[documentTypeKey]}
                    </option>
                  ))}
              </select>
            </div>
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={visitor.cedula || ''}
                onChange={this.handleInputChange}
                name="cedula"
                id="cedula"
                type="text"
                placeholder="# documento"
                required
              />
            </div>
            {errors.cedula && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar la cédula</span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={visitor.name || ''}
                onChange={this.handleInputChange}
                name="name"
                id="name"
                type="text"
                placeholder="Nombre y apellido"
                required
              />
            </div>
            {errors.name && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar el nombre</span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={visitor.phone || ''}
                onChange={this.handleInputChange}
                name="phone"
                id="phone"
                type="number"
                placeholder="Teléfono"
                required
              />
            </div>
            {errors.phone && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar el número de teléfono</span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={visitor.email || ''}
                onChange={this.handleInputChange}
                name="email"
                id="email"
                type="email"
                placeholder="Correo electronico"
                required
              />
            </div>
            {errors.email && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar el correo electrónico</span>
              </div>
            )}

            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={visitor.password || ''}
                onChange={this.handlePasswordChange}
                name="password"
                id="password"
                type="password"
                placeholder="Contraseña"
                required
              />
            </div>
            {errors.password && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar una contraseña</span>
              </div>
            )}
            <div className="skew-input w-100 mx-auto">
              <FormInput
                value={visitor.passwordConfirmation || ''}
                onChange={this.handlePasswordChange}
                name="passwordConfirmation"
                id="passwordConfirmation"
                type="password"
                placeholder="Confirmar contraseña"
                required
              />
            </div>
            {errors.passwordConfirmation && (
              <div className="error-form-container">
                <span className="error-text">* Debes confirmar la contraseña</span>
              </div>
            )}
            {errors.passwordsMatch && (
              <div className="error-form-container">
                <span className="error-text">* Las contraseñas no coinciden</span>
              </div>
            )}

            <div className="form-check">
              <input
                id="termsCheckbox"
                className="form-check-input"
                onChange={this.handleCheckboxChange}
                type="checkbox"
                name="acceptTerms"
                checked={visitor.acceptTerms || false}
              />
              <label className="form-check-label" htmlFor="termsCheckbox">
                He leido y acepto los
              </label>
            </div>
            <strong className="terms-condititions-link" onClick={() => this.setState({ showTermsModal: true })}>
              Términos y condiciones
            </strong>
            {showTermsModal ? <TerminosYCondiciones onModalClosed={this.handleCloseTermsModal.bind(this)} /> : null}
            {errors.acceptTerms && (
              <div className="error-form-container" style={{ marginTop: 0 }}>
                <span className="error-text">* Debes aceptar los términos y condiciones</span>
              </div>
            )}

            <div className="w-100 mx-auto d-flex justify-content-center">
              {loading ? (
                <Loader />
              ) : (
                <FormButton
                  type="submit"
                  value="Registrarse como Visitante"
                  textbutton="Registrarse como Visitante"
                  className="btn w-100 mx-auto button-form mt-1"
                />
              )}
            </div>

            <div className="col-sm-12 link-option-container">
              <span>¿ No es un visitante frecuente ?</span>
              <div className="row w-100 text-form">
                <div className="col-sm-12">
                  <Link className="link-option" to="/apartment/visitor/express/register">
                    Registro Rápido de Visitantes
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  areValidPasswords = () => {
    const {
      visitor: { password, passwordConfirmation },
    } = this.state;

    if (password !== passwordConfirmation) {
      const errors = { passwordsMatch: true };
      this.setState({ errors: { ...errors } });
      return false;
    }
    return true;
  };

  fixPhone = (phone) => `+57${phone.replace(/\s/g, '')}`;

  handleSubmit = async (event) => {
    this.setState({
      loading: true,
    });
    try {
      event.preventDefault();
      const { visitor, formData, previewPhoto } = this.state;
      const mandatoryFields = validateMandatoryFields({ ...visitor, previewPhoto }, [
        'cedula',
        'password',
        'passwordConfirmation',
        'name',
        'phone',
        'email',
        'previewPhoto',
        'phone',
        'buildingId',
      ]);

      if (!visitor.acceptTerms) {
        mandatoryFields.acceptTerms = '* El campo es obligatorio';
      }

      this.setState({ errors: mandatoryFields });

      if (!Object.keys(mandatoryFields).length && this.areValidPasswords()) {
        formData.set('name', visitor.name);
        formData.set('cedula', visitor.cedula);
        formData.set('documentType', visitor.documentType);
        formData.set('password', visitor.password);
        formData.set('passwordConfirmation', visitor.passwordConfirmation);
        formData.set('phone', this.fixPhone(visitor.phone));
        formData.set('email', visitor.email);
        formData.set('buildingId', visitor.buildingId);

        const visitorRegistred = await VisitorRegister(formData);
        localStorage.setItem('userCedula', visitor.cedula);
        console.log('response', visitorRegistred);
        this.props.history.push('/login');
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      const errorsList = getErrorsList(error);
      showErrorList(errorsList);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      visitor: {
        ...this.state.visitor,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleCheckboxChange = (event) => {
    const name = event.target.name;
    const newValue = !this.state.visitor[name];

    this.setState({
      visitor: {
        ...this.state.visitor,
        [name]: newValue,
      },
    });
  };

  handlePasswordChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      visitor: {
        ...this.state.visitor,
        [name]: value,
      },
    });
  };

  handleImage = async (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    this.state.formData.set('photo', files[0]);
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      this.setState({
        previewPhoto: reader.result,
        formData: this.state.formData,
        visitor: {
          ...this.state.visitor,
        },
      });
    };
  };

  updateVisitorState = (name, value) => {
    this.setState({
      visitor: Object.assign({}, this.state.visitor, { [name]: value }),
    });
  };

  updateState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  goBack() {
    window.history.back();
  }
}

FormApartmentVisitorRegister.propTypes = {
  history: PropTypes.object,
};

export default FormApartmentVisitorRegister;
