import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
/** Components */
import EmployeesApartmentRequests from './EmployeesApartmentRequests';
import OwnersApartmentRequests from './OwnersApartmentRequests';
import Header from '../../components/header';
import MenuInferior from '../../components/menuInferior.jsx';
import IncomingCall from '../../components/Call/IncomingCall';
import Camaras from '../camaras';
/** Temp mocks */
import defaultUser from '../../assets/img/default-user.svg';
import { getCurrentUser } from '../../utils/services/authService';
import { getUserInfo } from '../../utils/services/userService';
import Buildings from './Buildings.jsx';

export class buildingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      callModal: false,
      callFrom: null,
      callId: null,
      showCallModal: true,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken');
    if (token) {
      const result = await getCurrentUser(token);
      const user = getUserInfo(result.data);

      if (user.role.name === 'PORTERIA') this.props.history.push('/acceso-visitante');

      if (!user.role) {
        this.props.history.push('/');
      }
    }
  }

  render() {
    const { match } = this.props;
    return (
      <div>
        <Header userimage={defaultUser} />
        <div className="dashboard-container">
          <Route exact path={match.path} component={Camaras} />
          <Route path={`${match.path}/camaras`} component={Camaras} />

          <Route path={`${match.path}/apartments/requests/employee`} component={EmployeesApartmentRequests} />
          <Route path={`${match.path}/apartments/requests/owner`} component={OwnersApartmentRequests} />
          <Route path={`${match.path}/apartments/requests/admin`} component={Buildings} />
        </div>
        <IncomingCall />
        <MenuInferior />
      </div>
    );
  }
}

/** Types to avoid props properties warning */
buildingDashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }),
};

export default buildingDashboard;
