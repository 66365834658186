import React from 'react';
import { useSocket } from '../contexts/socketContext';
import { useNetworkStatus } from './useNetworkStatus';

const NetworkStatus = () => {
  const { isSocketConnected } = useSocket();
  const { isOnline } = useNetworkStatus();
  const deviceType = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) ? 'teléfono' : 'computador';

  return (
    <>
      {!isOnline && (
        <>
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content bg-blue rounded">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title text-white">Error de Conexión</h5>
                </div>
                <div className="modal-body">
                  <div className="p-2">
                    <span
                      className="material-icons d-flex justify-content-center text-white"
                      style={{ fontSize: '50px' }}
                    >
                      wifi_off
                    </span>
                  </div>
                  <div className="bg-white p-3 rounded">
                    <p className="text-center">¡Vaya! Parece que no tienes conexión a internet en tu {deviceType}.</p>
                    <p className="text-justify">
                      No te preocupes, aquí te dejamos algunas sugerencias para solucionar el problema:
                    </p>
                    <ul>
                      <li>Verifica tu red y vuelve a intentarlo.</li>
                      <li>Prueba recargar la página.</li>
                      <li>Es posible que tu sesión haya expirado, ¡intenta cerrar sesión y volver a abrirla!</li>
                      <li>Si puedes, busca una ubicación con mejor señal de internet.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </>
      )}
      {!isSocketConnected && isOnline && (
        <>
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content bg-blue rounded">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title text-white">Error de Conexión</h5>
                </div>
                <div className="modal-body">
                  <div className="p-2">
                    <span
                      className="material-icons d-flex justify-content-center text-white"
                      style={{ fontSize: '50px' }}
                    >
                      portable_wifi_off
                    </span>
                  </div>
                  <div className="bg-white p-3 rounded">
                    <p className="text-center">Parece que hay un problema con la conexión del edificio.</p>
                    <p>Por favor, prueba lo siguiente:</p>
                    <ul>
                      <li>
                        Si el edificio no tiene internet, consulta con el administrador para conocer el estado de la
                        cuenta.
                      </li>
                      <li>
                        Si el internet no es estable, puedes ponerte en contacto con soporte de Blue Building para más
                        ayuda.
                      </li>
                    </ul>
                    <p className="text-center">
                      <b>¡Esperamos que pronto se solucione!</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </>
      )}
    </>
  );
};

export default NetworkStatus;
