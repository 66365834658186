import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../logo';
import { FormInput, FormButton } from '../components/campo.js';
import '../components/form.css';
import { userAuthResetPassword } from '../utils/services/userService';

export class FormRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      urlToken: '',
      token: '',
      error: null,
    };
  }

  componentDidMount() {
    const token = new URLSearchParams(this.props.location.search).get('token');
    this.setState({
      token,
    });
  }

  render() {
    return (
      <div className="App bg-blue">
        <div className="container">
          <Logo className="App-logo w-75" />
          <form className="row d-flex w-75 mx-auto" onSubmit={this.handleSubmit}>
            <div className="skew-input input-shape w-100 mx-auto">
              <FormInput
                id="form-password"
                type="password"
                name="newPassword"
                placeholder="Contraseña"
                className="w-100"
                value={this.newPassword}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className="skew-input input-shape-2 w-100 mx-auto">
              <FormInput
                id="form-password"
                type="password"
                name="confirmPassword"
                placeholder="Confirmar Contraseña"
                className="w-100"
                value={this.confirmPassword}
                onChange={this.handlePassConfirm}
                required
              />
            </div>
            <span className="error">{this.state.error}</span>
            <FormButton type="submit" value="Iniciar" textbutton="Aceptar" className="btn w-75 mx-auto" />
          </form>
        </div>
      </div>
    );
  }

  handleInputChange = (event) => {
    console.log(event);
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.confirmPassword !== '') {
      if (event.target.value !== this.state.confirmPassword) {
        console.log('No coinciden');
        this.setState({ error: 'Las contraseñas no coinciden' });
      } else {
        this.setState({ error: null });
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { newPassword } = this.state;
    const data = {
      password: newPassword,
    };
    this.changePass(data);
  };

  handlePassConfirm = (event) => {
    console.log('confirmar contrasena');
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value !== this.state.newPassword) {
      console.log('No coinciden');
      this.setState({ error: 'Las contraseñas no coinciden' });
    } else {
      this.setState({ error: null });
    }
  };

  async changePass(data) {
    console.log(`FormRecovery -> changePass -> data`, data);
    console.log(`FormRecovery -> changePass -> this.state.token`, this.state.token);
    try {
      await userAuthResetPassword({ ...data, token: this.state.token });
      this.props.history.push('/');
    } catch (error) {
      console.log(`FormRecovery -> changePass -> error`, error);
      this.setState({ error: error });
    }
  }
}

FormRecovery.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default FormRecovery;
