import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyBsJLadCWJ6R1Y8s3s9yl97GF1Mn--SYXU',
  authDomain: 'bluebuilding1328.firebaseapp.com',
  databaseURL: 'https://bluebuilding1328.firebaseio.com',
  projectId: 'bluebuilding1328',
  storageBucket: 'bluebuilding1328.appspot.com',
  messagingSenderId: '117190936698',
  appId: '1:117190936698:web:bcb8e617b2add6f59d13c3',
  measurementId: 'G-ZTMTBZY6MC',
};

firebase.initializeApp(config);

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    'BKfIvKbVPp6sToR6u1D7pegqMiB5fLiX8M53KxhaUrSPx5F3-HKichS2dQ4ElhMBsfLqThwB8NKo_xvaLodYOzk',
  );
  messaging.onMessage((payload) => {
    console.log('**** * * * * * Message received. ', payload);
  });
}
export const requestFirebaseNotificationPermission = async () => {
  if (!firebase.messaging.isSupported()) return;
  try {
    await messaging.requestPermission();
    return await messaging.getToken();
  } catch (error) {
    console.log('error requesting permissions', error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
