import { url_base } from '../../config/url';
import axios from 'axios';

export async function getAccessibleDoors(buildingId, token) {
  const {
    data: { doors },
  } = await axios({
    method: 'get',
    url: `${url_base}/api/buildings/${buildingId}/doors`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`,
    },
  });

  return doors;
}

export async function getDoorsByBuildingId(buildingId) {
  const result = await axios({
    method: 'post',
    url: `${url_base}/api/bluebuilding/doors`,

    headers: {
      Authorization: `JWT ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
    data: { buildingId },
  });

  return result?.data;
}
