import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getApartmentUsersByName } from '../../utils/api';

import { FormInput } from '../campo';

import './apartmentsList.css';

function ApartmentsList({ onCallApartment }) {
  const [filterValue, setFilterValue] = useState('');
  const [apartments, setApartments] = useState([]);

  const fetchApartments = async (filterValue) => {
    const data = await getApartmentUsersByName(filterValue);
    setApartments(data);
  };

  useEffect(() => {
    if (!filterValue) setApartments([]);

    fetchApartments(filterValue);
  }, [filterValue]);

  return (
    <div className="apartments-list-container">
      <div className="skew-input input-shape-2 w-100 mx-auto">
        <FormInput
          id="apartments-input-filter"
          type="text"
          name="apartment-filter"
          placeholder="Número de Apartamento"
          className="w-100"
          value={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value.trimStart());
          }}
        />
      </div>
      :
      {apartments?.length > 0 ? (
        <div className="apartments-list-container">
          <div className="modal-body">
            <table className="table apartments-list-table">
              <thead>
                <tr>
                  <th scope="col">Apartamento</th>
                  <th scope="col">Torre</th>
                  <th scope="col">
                    <span className="material-icons">call</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {apartments.map((apartment) => {
                  const { id, name, tower } = apartment;
                  return (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{tower?.name}</td>
                      <td className="button-column">
                        <button
                          type="button"
                          className="btn btn-outline-success call-duplicated-button"
                          onClick={() => {
                            setFilterValue('');
                            onCallApartment(apartment);
                          }}
                        >
                          <span className="material-icons">call</span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {apartments?.length === 0 && filterValue ? (
        <div className="alert alert-warning" role="alert">
          Ningún apartamento coincide con tu búsqueda.
        </div>
      ) : null}
    </div>
  );
}

ApartmentsList.propTypes = {
  onCallApartment: PropTypes.func.isRequired,
};

export default ApartmentsList;
