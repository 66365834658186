import React, { Component } from 'react';
import PropTypes from 'prop-types';
/** Images */
import defaultUser from '../assets/img/default-user.svg';
/** Services / Utils */
import { showErrorAlert } from '../utils/alerts';
import { getApartmentVisitors, createApartmentVisitor } from '../utils/api';
import { validateMandatoryFields } from '../utils/validator';

class KnownVisitorManager extends Component {
  constructor(props) {
    super(props);

    const formData = new FormData();

    this.state = {
      visitor: {
        name: '',
        cedula: null,
      },
      formData,
      previewPhoto: null,
      view: 'list',
      visitors: [],
      visitorsFiltered: [],
      photosSrc: {
        face: null,
        id: null,
      },
    };
  }

  async componentDidMount() {
    try {
      const {
        data: { visitors },
      } = await getApartmentVisitors(this.props.apartmentId);
      const visitorsFiltered = [...visitors];
      this.setState({
        visitors,
        visitorsFiltered,
      });
    } catch (error) {
      showErrorAlert('Ups', `Error ${error.message}`);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.photosSrc.formFace !== this.props.photosSrc.formFace ||
      prevProps.photosSrc.formId !== this.props.photosSrc.formId
    ) {
      this.setState({
        photosSrc: this.props.photosSrc,
      });
      if (this.props.photosBlob) {
        this.state.formData.set('photo', this.props.photosBlob.formFace);
        this.state.formData.set('cedulaFrontal', this.props.photosBlob.formId);
      }
    }
  }

  handleSearchvisitors(event) {
    const { visitors } = this.state;
    const query = event.target.value.toLowerCase();
    const visitorsFiltered = visitors.filter((contact) => contact.name.toLowerCase().indexOf(query) >= 0);
    this.setState({
      visitorsFiltered,
    });
  }

  handleViewChange(view) {
    this.setState({
      view,
    });
  }

  /** FORM */

  handleTakePhoto(type) {
    console.log('Taking photo from Form');
    this.props.onTakePhotoEvent(type);
  }

  handleInputChange(event) {
    this.setState({
      visitor: {
        ...this.state.visitor,
        [event.target.name]: event.target.value,
      },
    });
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      const { visitor, formData, photosSrc } = this.state;
      const mandatoryFields = validateMandatoryFields({ ...visitor, ...photosSrc }, ['name', 'formFace', 'formId']);
      this.setState({ errors: mandatoryFields });

      if (!Object.keys(mandatoryFields).length) {
        formData.set('name', visitor.name);
        formData.set('type', 'VISITANTE_CONOCIDO');
        formData.set('callId', this.props.callId);
        const visitorCreated = await createApartmentVisitor(this.props.apartmentId, formData);
        this.handleSelectVisitor(visitorCreated.data);
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log('KnownVisitorManager -> handleSubmit -> error', error);
      this.setState({
        loading: false,
      });
      showErrorAlert('¡Ups!', 'Hubo un error en el proceso de su registro.');
    }
  }

  handleSelectVisitor(visitor) {
    this.props.onVisitorSelected(visitor);
  }

  render() {
    const { photosSrc, visitorsFiltered, view } = this.state;
    return (
      <div className="opc-llamada tab-content list-group" id="known-list-container">
        {view === 'form' ? (
          <div className="tab-pane active" id="form-knowns">
            <div className="buscador">
              <button className="btn btn-primary back-btn" onClick={this.handleViewChange.bind(this, 'list')}>
                <span className="material-icons">arrow_back_ios</span>
              </button>
            </div>
            <div className="text-container">
              <p>Toma las fotos de rostro y documento para permitir el ingreso</p>
            </div>
            <div className="panel-images-container">
              <div className="photo-container" onClick={this.handleTakePhoto.bind(this, 'formFace')}>
                <span className="form-icon material-icons">portrait</span>
                {photosSrc.formFace && (
                  <img className="face-photo" src={photosSrc.formFace} alt="Foto rostro conocido" />
                )}
              </div>
              <div className="photo-container" onClick={this.handleTakePhoto.bind(this, 'formId')}>
                <span className="form-icon material-icons">featured_play_list</span>
                {photosSrc.formId && <img className="id-photo" src={photosSrc.formId} alt="Foto documento conocido" />}
              </div>
            </div>
            <form className="contact-form" onSubmit={this.handleSubmit.bind(this)}>
              <input
                id="name-conocido"
                type="text"
                placeholder="Nombre conocido"
                name="name"
                onChange={this.handleInputChange.bind(this)}
                required
              />
              <button type="submit" className="btn btn-primary call-button">
                <span className="material-icons">save</span>
              </button>
              <button type="button" className="btn btn-danger call-button" onClick={this.props.onCallEnded}>
                <span className="material-icons">call_end</span>
              </button>
            </form>
          </div>
        ) : (
          <div className="tab-pane active" id="knowns-list">
            <div className="buscador">
              <button className="btn btn-primary back-btn" onClick={this.handleSelectVisitor.bind(this, null)}>
                <span className="material-icons">arrow_back_ios</span>
              </button>
              <input type="text" placeholder="buscar" onChange={this.handleSearchvisitors.bind(this)} />
              <input type="submit" />
            </div>
            <div>
              <ul>
                {visitorsFiltered.length > 0 &&
                  visitorsFiltered.map((visitor) => (
                    <li key={visitor.id} onClick={this.handleSelectVisitor.bind(this, visitor)}>
                      <a>
                        <img src={visitor.image || defaultUser} />
                        <p>{visitor.name}</p>
                      </a>
                    </li>
                  ))}
                {visitorsFiltered.length === 0 && (
                  <div className="alert alert-warning" role="alert">
                    No se encontró ningún visitante registrado
                  </div>
                )}
              </ul>
            </div>
            <div id="btn-desconocido" className="btnMas" onClick={this.handleViewChange.bind(this, 'form')}>
              +
            </div>
          </div>
        )}
      </div>
    );
  }
}

KnownVisitorManager.propTypes = {
  callId: PropTypes.string,
  apartmentId: PropTypes.string,
  photosBlob: PropTypes.any,
  photosSrc: PropTypes.any,
  onTakePhotoEvent: PropTypes.func,
  onVisitorSelected: PropTypes.func,
  onCallEnded: PropTypes.func,
};

export default KnownVisitorManager;
