import React from 'react';

import './loader.css';

function Loader() {
  return (
    <div className="wrapper">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
      <span>Cargando</span>
    </div>
  );
}

export default Loader;
