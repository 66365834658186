import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import socket from '../utils/socket';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [isSocketConnected, setIsSocketConnected] = useState(SocketContext.isConnected);

  socket.on('connect', () => setIsSocketConnected(true));
  socket.on('disconnect', () => setIsSocketConnected(false));

  return <SocketContext.Provider value={{ socket, isSocketConnected }}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSocket = () => useContext(SocketContext);
