import React, { Component } from 'react';
import PropTypes from 'prop-types';

import logoBlue from './assets/img/bluebuilding.svg';

export class Logo extends Component {
  render() {
    const classContainer = this.props.isBgBlue ? 'bg-blue' : '';
    const imgClass = this.props.isBgBlue ? 'bg-blue-image' : '';
    return (
      <div className={classContainer}>
        <img src={logoBlue} className={`${imgClass} App-logo w-50`} alt="bluebuilding" />
      </div>
    );
  }
}

Logo.propTypes = {
  isBgBlue: PropTypes.bool,
};

export class LogoPeque extends Component {
  render() {
    return (
      <div>
        <img src={logoBlue} className="logo-peque w-50" alt="bluebuilding" />
      </div>
    );
  }
}

export default (LogoPeque, Logo);
