import { url_base } from '../../config/url';
import axios from 'axios';

export async function getBuildings() {
  try {
    const {
      data: { buildings },
    } = await axios({
      method: 'get',
      url: `${url_base}/api/buildings`,
    });
    return buildings;
  } catch (error) {
    console.error('getBuildings --> Error', error);
    return null;
  }
}

export function getBuildingTowers(buildingId) {
  return axios({
    method: 'get',
    url: `${url_base}/api/buildings/${buildingId}/towers`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getBuildingApartments(buildingId) {
  return axios({
    method: 'get',
    url: `${url_base}/api/buildings/${buildingId}/apartments?active=true`,
    headers: {
      Authorization: `JWT ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
  });
}

export function grantOwnerAccess(buildingId, accessId, token, data) {
  return axios({
    method: 'post',
    url: `${url_base}/api/buildings/${buildingId}/access/${accessId}`,
    headers: {
      Authorization: 'JWT ' + token,
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function getOwnerAccess(buildingId, token) {
  return axios({
    method: 'get',
    url: `${url_base}/api/buildings/${buildingId}/access/`,
    headers: {
      Authorization: 'JWT ' + token,
      'Content-Type': 'application/json',
    },
  });
}

export function buildingAuthLogin(data) {
  return axios({
    method: 'post',
    url: `${url_base}/api/buildings/auth/login`,
    headers: {
      'Content-Type': 'application/json',
    },
    maxContentLength: 2000,
    maxBodyLength: 2000,
    data,
  });
}

export function getVisitCode(buildingId, code, token) {
  return axios({
    method: 'get',
    url: `${url_base}/api/buildings/${buildingId}/visits/${code}`,
    headers: {
      Authorization: 'JWT ' + token,
      'content-type': 'multipart/form-data',
    },
  });
}

export function updateVisitCode(buildingId, code, token) {
  return axios({
    method: 'put',
    url: `${url_base}/api/buildings/${buildingId}/visits/${code}`,
    headers: {
      Authorization: 'JWT ' + token,
      'content-type': 'multipart/form-data',
    },
  });
}
