import React, { Component } from 'react';
import PropTypes from 'prop-types';
import socket from '../utils/socket';
import { isFunction as _isFunction } from 'lodash';
/** Services/ Utils */
import { getCall, updateCall, createApartmentVisitor } from '../utils/api';
import PeerConnection from '../helpers/PeerConnection';
/** CSS */
import '../assets/css/llamada.css';
import '../components/form.css';
import KnownVisitorManager from './knownVisitorManager';
import { showErrorAlert } from '../utils/alerts';
import { getCurrentUser } from '../utils/services/authService';
import UnknownVisitorManager from './unknownVisitorManager';

export class LlamadaVisitante extends Component {
  constructor(props) {
    super(props);

    const callFrom = localStorage.getItem('callFrom');
    const callId = props.match.params.callId;
    const isFromLobby = props.match.params.isFromLobby === 'true';
    const videoStyle = isFromLobby ? {} : { display: 'none' };

    this.state = {
      callFrom,
      callId,
      isFromLobby,
      videoStyle,
      peerSrc: null,
      panel: 'initial',
      error: null,
      photosSrc: {
        face: null,
        id: null,
        formFace: null,
        formId: null,
      },
      userRole: localStorage.getItem('userRole'),
    };
    this.pc = null;
    this.call = null;
    this.visitorId = null;
    this.errorCleaner = null;
    this.buildingId = null;

    this.canvasRef = React.createRef();
    this.photosBlob = {
      face: null,
      id: null,
    };

    this.renderAccessButton = this.renderAccessButton.bind(this);
  }

  async componentDidMount() {
    try {
      const call = await getCall(this.state.callId);

      const token = localStorage.getItem('authToken');
      const {
        data: { spaces },
      } = await getCurrentUser(token);
      const [{ building }] = spaces;
      this.buildingId = building.id;
      this.call = call;
      console.table(this.call);
      // go back to /camaras if call is already finished
      if (!call || ['TERMINADA', 'PERDIDA', 'RECHAZADA'].indexOf(call.status) !== -1) {
        showErrorAlert('¡Ups!', 'Esta llamada ya no se encuentra disponible');
        return this.goBack();
      } else if (call.status === 'ENTRANTE') {
        await this.acceptCall(call);
      }

      // start streaming peer video into tenants apartment this way tenat can
      // see peers video but not the other way (peer can't see tenants video)
      // peer is the intercom (citofono)
      this.config = {
        audio: { echoCancellation: true, noiseSuppresion: true },
        // video: this.state.isFromLobby ? false : { facingMode: "user" },
        video: { facingMode: 'user' },
      };
      console.log({ isFromLobby: this.state.isFromLobby });
      console.log({ configLlamadaEntrante: this.config });

      this.pc = new PeerConnection(this.state.callId, this.state.callFrom)
        .on('peerStream', (stream) => {
          const newState = { peerSrc: stream };
          this.setState(newState);

          this.setMediaStream();
        })
        .start(false, this.config);

      socket.on('end', this.handleFinishCall.bind(this));
      socket.on('door_action:error', ({ error }) => {
        const message = error.message || JSON.stringify(error);
        console.error(message);
        this.setState({ error: message });
        /** Reset error */
        this.errorCleaner = setTimeout(() => {
          this.setState({
            error: null,
          });
        }, 5000);
      });
    } catch (err) {
      showErrorAlert('Ups', `Ha ocurrido un error ${err}`);
      console.error(err);
      this.goBack();
    }
  }

  componentWillUnmount() {
    this.setState({
      peerSrc: null,
      callFrom: null,
      callId: null,
    });
    socket.off('end');
    localStorage.removeItem('callFrom');
  }

  setMediaStream() {
    const { peerSrc } = this.state;
    const { peerVideo } = this;
    if (peerVideo && peerSrc) {
      peerVideo.srcObject = peerSrc;
      // it seems iphone doesn't allow videos to autoplay if they are not muted,
      // but as we are streaming video calls, we should not have muted calls,
      // so we need to play videos automatically once we load its source
      // - autoplay muted playsInline - works
      // - autoplay playsInline - doesn't work (unmuted)
      peerVideo.onloadedmetadata = function () {
        peerVideo.play();
        peerVideo.muted = false;
      };
    }
  }

  async acceptCall(call) {
    try {
      const { data } = await updateCall(call.id, { status: 'CONTESTADA' });
      call = data.call;
      socket.emit('call_accepted', {
        to: call.caller.id,
        callId: call.id,
      });

      localStorage.setItem('callFrom', call.caller.id);
    } catch (e) {
      console.error(e);
    }
  }

  async handleFinishCall() {
    try {
      await updateCall(this.state.callId, { status: 'TERMINADA' });
      if (this.pc && _isFunction(this.pc.stop)) this.pc.stop(true);
      this.pc = null;
      this.config = null;
      this.call = null;
      this.goBack();
    } catch (e) {
      console.error(e);
    }
  }

  setPanel(type) {
    this.setState({
      panel: type,
    });
  }

  setImageSrc(type, src) {
    this.setState({
      photosSrc: {
        ...this.state.photosSrc,
        [type]: src,
      },
    });
  }

  async createUnknownVisitor() {
    try {
      const formData = new FormData();
      formData.set('photo', this.photosBlob.face);
      formData.set('cedulaFrontal', this.photosBlob.id);
      formData.set('callId', this.call.id);
      formData.set('type', 'VISITANTE_DESCONOCIDO');
      return createApartmentVisitor(this.call.apartment.id, formData);
    } catch (error) {
      showErrorAlert('Ups', `Error ${error}`);
      return null;
    }
  }

  evaluatePhotosReady() {
    const { photosSrc } = this.state;
    if (photosSrc.face && photosSrc.id) {
      this.setState({
        panel: 'readyToOpen',
      });
    }
  }

  handleVisitorSelected(visitor) {
    console.log('visitor', visitor);
    const panel = visitor ? 'readyToOpen' : 'initial';
    this.visitorId = visitor ? visitor.id : null;
    this.setPanel(panel);
  }

  // capture the currently displayed video frame, convert it into a PNG file,
  // and display it in the captured frame box
  takePhoto(type) {
    console.log(`take picture ${type}`);
    const { canvasRef, photosBlob, peerVideo } = this;

    const width = peerVideo.videoWidth;
    const height = peerVideo.videoHeight;

    const photoCanvas = canvasRef.current;

    // capture the whole video
    photoCanvas.setAttribute('width', width);
    photoCanvas.setAttribute('height', height);
    // stream the video to canvas so we can capture image
    const context = photoCanvas.getContext('2d');
    context.drawImage(peerVideo, 0, 0, width, height);
    // get canvas data stream (current frame) and convert it to an image/png
    photoCanvas.toBlob((blob) => {
      photosBlob[type] = blob;
      const src = URL.createObjectURL(blob);
      this.setImageSrc(type, src);
      context.clearRect(0, 0, width, height);
      this.evaluatePhotosReady();
    });
  }

  async openDoor() {
    try {
      console.log('opening Door');
      if (!this.visitorId) {
        const visitorCreated = await this.createUnknownVisitor();
        this.visitorId = visitorCreated.data.id;
        console.log('%c Visitante desconocido creado', 'color: green');
      }
      if (this.errorCleaner) {
        clearTimeout(this.errorCleaner);
      }
      const buildingId = this.buildingId;
      /** TODO: change by rigth doorId */
      const door = '1';
      socket.emit('door_action', { buildingId, door });
    } catch (error) {
      showErrorAlert('Ups', `Error ${error}`);
    }
  }

  goBack() {
    this.props.history.push('/building/dashboard');
  }

  renderAccessButton() {
    const { error, panel, photosSrc } = this.state;
    return (
      <>
        <canvas id="photo-canvas" className="hidden-canvas" ref={this.canvasRef} />

        <div className="images-container">
          {photosSrc.face && (
            <div className="photo-container" onClick={this.takePhoto.bind(this, 'face')}>
              <span className="material-icons">party_mode</span>
              <img className="face-photo" src={photosSrc.face} alt="Foto desconocido" />{' '}
            </div>
          )}
          {photosSrc.id && (
            <div className="photo-container" onClick={this.takePhoto.bind(this, 'id')}>
              <span className="material-icons">party_mode</span>
              <img className="id-photo" src={photosSrc.id} alt="Foto Documento" />{' '}
            </div>
          )}
        </div>

        {panel === 'initial' && (
          <div className="btn-llamada d-flex list-group">
            <button
              className="btn botton btn-primary button-visitor"
              id="btn-known"
              onClick={this.setPanel.bind(this, 'known')}
            >
              Conocido
            </button>
            <button
              className="btn botton btn-secondary button-visitor"
              id="btn-unknown"
              onClick={this.setPanel.bind(this, 'unknown')}
            >
              Desconocido
            </button>
            <button type="button" className="btn btn-danger call-button" onClick={this.handleFinishCall.bind(this)}>
              <span className="material-icons">call_end</span>
            </button>
          </div>
        )}

        {panel === 'known' && (
          <KnownVisitorManager
            apartmentId={this.call.apartment.id}
            callId={this.call.id}
            photosBlob={this.photosBlob}
            photosSrc={photosSrc}
            onTakePhotoEvent={this.takePhoto.bind(this)}
            onVisitorSelected={this.handleVisitorSelected.bind(this)}
            onCallEnded={this.handleFinishCall.bind(this)}
          />
        )}

        {panel === 'unknown' && (
          <UnknownVisitorManager
            callId={this.call.id}
            photosBlob={this.photosBlob}
            photosSrc={photosSrc}
            onTakePhotoEvent={this.takePhoto.bind(this)}
            onVisitorSelected={this.handleVisitorSelected.bind(this)}
            onCallEnded={this.handleFinishCall.bind(this)}
          />
        )}
        {panel === 'readyToOpen' && (
          <div className="btn-llamada d-flex list-group">
            <button
              type="button"
              className="btn btn-primary call-button"
              id="btn-open-door"
              onClick={this.openDoor.bind(this)}
            >
              <span className="material-icons">meeting_room</span>
            </button>
            <button
              type="button"
              className="btn btn-danger call-button"
              id="btn-finish-call"
              onClick={this.handleFinishCall.bind(this)}
            >
              <span className="material-icons">call_end</span>
            </button>
          </div>
        )}
        {error && (
          <div className="error-container">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    const { userRole, videoStyle } = this.state;
    return (
      <div className="App llamada">
        <div className="videollamada">
          <button type="button" className="close" onClick={this.handleFinishCall.bind(this)}>
            <span aria-hidden="true">&times;</span>
          </button>

          <video ref={(el) => (this.peerVideo = el)} style={{ ...videoStyle }} muted playsInline />

          {userRole !== 'PORTERIA' ? (
            this.renderAccessButton()
          ) : (
            /** Only show the finish button if is calling from Lobby */
            <div className="btn-llamada d-flex list-group from-lobby">
              <button type="button" className="btn btn-danger call-button" onClick={this.handleFinishCall.bind(this)}>
                <span className="material-icons">call_end</span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

LlamadaVisitante.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default LlamadaVisitante;
