import { url_base } from '../../config/url';
import axios from 'axios';

export function getTowerFloors(towerId) {
  return axios({
    method: 'get',
    url: `${url_base}/api/towers/${towerId}/floors`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
