import React, { Component } from 'react';
import PropTypes from 'prop-types';
/** Component */
import CamaraModal from '../components/camaraModal';
import MapaHeader from '../components/mapaHeader';
import Mapa from '../components/mapa';
/** Services */
import { getCurrentUser } from '../utils/services/authService';
import { getTowerFloors } from '../utils/services/towerService';
import { getAccessibleDoors } from '../utils/services/doorService';
import { showErrorAlert } from '../utils/alerts';

export class Camaras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingId: null,
      options: {},
      selected: {},
    };
  }

  async componentDidMount() {
    try {
      const building = this.props.location?.state || null;
      await this.setInitialData(building);
    } catch (error) {
      showErrorAlert('Error', error.message);
    }
  }

  async setInitialData(buildingData) {
    try {
      let building = buildingData;

      const token = localStorage.getItem('authToken');
      if (!buildingData) {
        const { data } = await getCurrentUser(token);
        building = data.spaces?.[0]?.building;

        if (!building) {
          throw new Error('No se encontró un edificio asociado al usuario.');
        }
      }

      const doors = await getAccessibleDoors(building.id, token);

      if (!doors.length) {
        throw new Error(`No se encontraron puertas para el edificio '${building.name}' .`);
      }

      const { tower } = doors[0];
      const { data: floors } = await getTowerFloors(tower.id);

      if (!floors.length) {
        throw new Error(`No se encontraron pisos para la torre '${tower.name}'.`);
      }

      const options = {
        tower,
        floors: floors || [],
        doors: doors || [],
      };

      const floor = floors[0] || null;
      const selected = {
        floor,
        doors: floor ? doors.filter((door) => door?.floor?.id === floor?.id) : [],
      };
      this.setState({ options, selected, buildingId: building.id });
    } catch (error) {
      const message = error.message || JSON.stringify(error);
      console.error(message);
      showErrorAlert(
        '!Ups¡ Error al cargar los datos',
        `Hubo un problema al obtener la información necesaria. Motivo: ${message}`,
      );
    }
  }

  handleFloorChanged(floorId) {
    if (floorId) {
      const floor = this.state.options.floors.find((floor) => floor.id === floorId);
      const doors = this.state.options.doors.filter((door) => door && door.floor && door.floor.id === floor.id);
      const selected = {
        floor,
        doors,
      };
      this.setState({
        selected,
      });
    }
  }

  handleCloseModal() {
    this.setState({
      doorSelected: null,
    });
  }

  updateCamera(door) {
    this.setState({
      doorSelected: door,
    });
  }

  render() {
    const { buildingId, doorSelected, options, selected } = this.state;

    return (
      <div className="camaras-container">
        <MapaHeader
          selection={selected}
          floors={options.floors}
          label="Selecciona el nivel que quieres ver."
          onFloorChanged={this.handleFloorChanged.bind(this)}
        />
        <div className="mapa">
          <div className="image-container">
            <img className="mapa-img" alt="" src={selected && selected.floor && selected.floor.image} />

            {selected &&
              selected.doors &&
              selected.doors.map((door) => (
                <Mapa
                  key={door.id}
                  door={door}
                  doorId={door.id}
                  camId={door.cameraId}
                  cam01Left={door.left}
                  cam01Top={door.top}
                  nameCamara={door.name}
                  datatoggle="modal"
                  datatarget="#video01"
                  cameraSelected={this.updateCamera.bind(this)}
                />
              ))}
          </div>
        </div>
        {doorSelected && (
          <CamaraModal door={doorSelected} buildingId={buildingId} onModalClosed={this.handleCloseModal.bind(this)} />
        )}
      </div>
    );
  }
}

Camaras.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  history: PropTypes.object.isRequired,
};

export default Camaras;
