import React from 'react';
import PropTypes from 'prop-types';

import './loading-spinner.css';

const LoadingSpinner = ({ size }) => {
  const styles = size ? { width: size, height: size } : {};
  return <div className="loading-spinner" style={styles}></div>;
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
};

export default LoadingSpinner;
