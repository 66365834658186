import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/modalvideo.css';
import { LogoPeque } from '../logo';
import socket from './../utils/socket';

export class ModalPanico extends Component {
  constructor(props) {
    super(props);
  }

  seguridad = () => {
    var data = {
      type: 'Seguridad',
      user: this.props.user,
      camera: this.props.camera,
      torre: this.props.torre,
      nivel: this.props.nivel,
      fecha: Date().toString(),
    };
    socket.emit('seguridad', data);
  };
  emergencia = () => {
    var data = {
      type: 'Emergencia',
      user: this.props.user,
      camera: this.props.camera,
      torre: this.props.torre,
      nivel: this.props.nivel,
      fecha: Date().toString(),
    };
    socket.emit('emergencia', data);
  };

  /* 
    render() {
      return (
          <div className="modal fade alerta" id="modalPanico" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLongTitle"><LogoPeque/></h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div className="modal-body">
                          Usa esta opción si presencias alguna actividad sospechosa en alguna de las instalaciones o si tienes un caso de emergencia.
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-secundary" data-dismiss="modal">cerrar</button>
                          <button type="button" className="btn btn-primary" onClick={this.seguridad} data-toggle="modal" data-target="#panicoactivo" data-dismiss="modal">Seguridad</button>
                          <button type="button" className="btn btn-primary" onClick={this.emergencia}data-toggle="modal" data-target="#panicoactivo" data-dismiss="modal">Emergencia</button>
                      </div>
                  </div>
              </div>
          </div>
      ); */

  // export class ModalPanico extends Component {
  //   render() {
  //     return (
  //         <div className="modal fade alerta" id="modalPanico" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  //             <div className="modal-dialog modal-dialog-centered" role="document">
  //                 <div className="modal-content">
  //                     <div className="modal-header">
  //                         <h5 className="modal-title" id="exampleModalLongTitle"><LogoPeque/></h5>
  //                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  //                             <span aria-hidden="true">&times;</span>
  //                         </button>
  //                     </div>
  //                     <div className="modal-body">
  //                         Usa esta opción si presencias alguna actividad sospechosa en alguna de las instalaciones o si tienes un caso de emergencia.
  //                     </div>
  //                     <div className="modal-footer">
  //                         <button type="button" className="btn btn-secundary" data-dismiss="modal">cerrar</button>
  //                         <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#panicoactivo" data-dismiss="modal">Seguridad</button>
  //                         <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#panicoactivo" data-dismiss="modal">Emergencia</button>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     );
  //   }
  // }

  //export class ModalPanico extends Component {

  render() {
    return (
      <div
        className="modal fade alerta"
        id="modalPanico"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                <LogoPeque />
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Usa esta opción si presencias alguna actividad sospechosa en alguna de las instalaciones o si tienes un
              caso de emergencia.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secundary" data-dismiss="modal">
                cerrar
              </button>
              <button type="button" className="btn btn-primary" onClick={this.seguridad}>
                Seguridad
              </button>
              <button type="button" className="btn btn-primary" onClick={this.emergencia}>
                Emergencia
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModalPanico.propTypes = {
  user: PropTypes.string,
  camera: PropTypes.string,
  torre: PropTypes.string,
  nivel: PropTypes.string,
};

export class Panicoactivo extends Component {
  restablecer = () => {
    var data = {
      type: 'Restablecer',
      user: this.props.user,
      camera: this.props.camera,
      torre: this.props.torre,
      nivel: this.props.nivel,
      fecha: Date().toString(),
    };
    socket.emit('restablecer', data);
  };
  render() {
    return (
      <div
        className="modal alerta fade"
        id="panicoactivo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <LogoPeque />
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">La alerta de Pánico está activa</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Entendido
              </button>
              <button type="button" className="btn btn-primary" onClick={this.restablecer}>
                Desactivar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Panicoactivo.propTypes = {
  user: PropTypes.string,
  camera: PropTypes.string,
  torre: PropTypes.string,
  nivel: PropTypes.string,
};

export default {
  ModalPanico,
  Panicoactivo,
};
