import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showSuccessAlert, showErrorAlert } from '../../utils/alerts';
/** Components */
import Loader from '../../components/loader';
import Logo from '../../logo';
import { FormInput, FormButton } from '../../components/campo.js';
/** Services */
import { getBuildings } from '../../utils/services/buildingService';
import { VisitorCreateVisit } from '../../utils/services/visitorService';
import { validateMandatoryFields } from '../../utils/validator';
/** CSS */
import '../../index.css';

export class buildingVisitsCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPhoto: '',
      formData: null,
      user: {
        cedula: '',
        buildingId: null,
      },
      errors: {},
      loading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCodeButton = this.handleCodeButton.bind(this);
  }

  async componentDidMount() {
    try {
      const cedula = localStorage.getItem('userCedula') || '';
      const buildings = await getBuildings();
      const buildingId = buildings && buildings.length === 1 ? buildings[0].id : '';
      const formData = new FormData();
      this.setState({
        formData,
        user: {
          buildingId,
          cedula,
        },
        buildings,
      });
    } catch (error) {
      console.log('componentDidMount -> error', error);
    }
  }

  render() {
    const { previewPhoto, errors, user, loading, buildings } = this.state;
    return (
      <div className="App bg-blue">
        <div className="container-form">
          <a onClick={this.goBack} className={'btn-back'} />
          <Logo className="App-logo w-75" />
          <form>
            <div
              style={{
                fontSize: '1.3em',
                margin: '1em',
                color: '#FFFFFF',
              }}
            >
              Solicitar Código de acceso
            </div>
            <div className="foto-user mx-auto">
              <div>
                {previewPhoto && (
                  <img
                    src={previewPhoto}
                    style={{ borderRadius: '50%' }}
                    alt="Imagen usuario"
                    width="110px"
                    height="110px"
                  />
                )}
              </div>
              <input type="file" name="photo" id="photo" accept="image/*" onChange={this.handleImage} />
            </div>
            {errors.previewPhoto && (
              <div className="error-form-image-container">
                <span className="error-image">* Debes agregar la foto</span>
              </div>
            )}
            <div className="skew-input input-shape w-100 mx-auto">
              <select value={user.buildingId || ''} name="buildingId" onChange={this.handleInputChange} id="buildingId">
                <option key="default_building" value="">
                  Selecciona un edificio
                </option>
                {buildings &&
                  buildings.map((building) => (
                    <option key={building.id} value={building.id}>
                      {building.name}
                    </option>
                  ))}
              </select>
            </div>
            {errors.buildingId && (
              <div className="error-form-container">
                <span className="error-text">* Debes seleccionar un edificio</span>
              </div>
            )}
            <div className="skew-input input-shape w-100 mx-auto">
              <FormInput
                id="form-cedula"
                type="text"
                name="cedula"
                placeholder="Cédula"
                value={user.cedula || ''}
                onChange={this.handleInputChange}
                required
              />
            </div>
            {errors.cedula && (
              <div className="error-form-container">
                <span className="error-text">* Debes diligenciar la cédula</span>
              </div>
            )}

            <div className="w-100 mx-auto d-flex justify-content-center">
              {loading ? (
                <Loader />
              ) : (
                <FormButton
                  type="button"
                  value="code"
                  name="cedula"
                  textbutton="Solicitar código"
                  className="btn w-75 mx-auto button-form"
                  onClick={this.handleCodeButton}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }

  async handleInputChange(event) {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleImage = async (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    this.state.formData.set('photo', files[0]);
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      this.setState({
        previewPhoto: reader.result,
        formData: this.state.formData,
      });
    };
  };

  async handleCodeButton() {
    this.setState({
      loading: true,
    });
    try {
      const {
        previewPhoto,
        formData,
        user: { cedula, buildingId },
      } = this.state;

      const mandatoryFields = validateMandatoryFields({ cedula, previewPhoto, buildingId }, [
        'cedula',
        'previewPhoto',
        'buildingId',
      ]);
      this.setState({ errors: mandatoryFields });
      if (!Object.keys(mandatoryFields).length) {
        formData.set('buildingId', buildingId);

        const {
          data: { code },
        } = await VisitorCreateVisit(cedula, formData);
        console.log('buildingVisitsCode -> handleCodeButton -> verificationCode', code);
        showSuccessAlert(
          '¡Bienvenido!',
          `<div>Su código de acceso es <strong class='code'>${code}</strong></div>`,
          true,
        );
      } else {
        showErrorAlert('Formulario Incompleto', 'Por favor completar todos los campos.');
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(`*****`);
      console.log(error.message);
      console.log(`*****`);

      await showErrorAlert('¡Ups!', 'Cédula no autorizada, debe registrarse primero.');
      this.props.history.push('/apartment/visitor/express/register');
      this.setState({
        loading: false,
      });
    }
  }

  goBack() {
    window.history.back();
  }
}

buildingVisitsCode.propTypes = {
  history: PropTypes.object,
};

export default buildingVisitsCode;
